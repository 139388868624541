import React, { useState } from 'react';

interface Data {
  name: string;
}




const FetchDataButton: React.FC = () => {
  const [data, setData] = useState<Data[]>([]);
  const [name, setName] = useState('');

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const fetchData = async () => {
    const url = new URL(process.env.REACT_APP_BACKEND_API_URL);
    url.searchParams.set('name', name);
    const response = await fetch(url.toString());
    const json = await response.json();
    setData(json);
  };

  return (
    <div>
      <input type="text" name="name" value={name} onChange={handleNameChange} />
      <button onClick={fetchData}>Fetch Data</button>
      <ul>
        
        {data.map((item) => (
          <li>
            {item.name} 
          </li>
        ))}
      </ul>
    </div>
  );
};

export {FetchDataButton};