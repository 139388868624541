import React from 'react';

import './App.css';
import { FetchDataButton } from './Button';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <FetchDataButton></FetchDataButton>
      </header>
    </div>
  );
}

export default App;
